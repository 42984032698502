<template>
  <!--begin::Dashboard-->
  <div class="row gy-5 g-xl-8">
    <VOnboardingWrapper ref="wrapper" :steps="steps" />
    <h1 id="hello">Hello, {{ loggedUserName }}!</h1>
    <div class="col-xl-6">
      <CalendarWidget id="calendar" />
    </div>
    <div class="col-xl-6">
      <EmailWidget id="new-emails" class="mb-10" />
      <PendingProspectsWidget id="pending-prospects" class="mb-10" @hot-prospect-accepted="refreshPatients" />
      <PatientsWidget id="recent-patients" ref="patientsWidget" />
    </div>
  </div>
  <Terms v-model="isTermsModalOpen" name="new-order-modal" />
  <!--end::Dashboard-->
</template>

<script>
import EmailWidget from "@/components/dashboard/EmailWidget";
import CalendarWidget from "@/components/dashboard/CalendarWidget";
import PatientsWidget from "@/components/dashboard/PatientsWidget";
import PendingProspectsWidget from "@/components/dashboard/PendingProspectsWidget";

import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import JwtService from "@/core/services/JwtService";
import { ref } from 'vue'
import { VOnboardingWrapper, useVOnboarding } from 'v-onboarding'
import 'v-onboarding/dist/style.css'
import Terms from "@/components/modals/dashboard/Terms.vue";

export default {
  components: {
    Terms,
    EmailWidget,
    CalendarWidget,
    PatientsWidget,
    PendingProspectsWidget,
    VOnboardingWrapper,
  },
  setup() {
    const wrapper = ref(null)
    const { start, goToStep, finish } = useVOnboarding(wrapper)
    const steps = [
      // Dashboard
      {
        attachTo: { element: '#hello' },
        content: {
          title: "💡 Welcome to your dashboard!",
          description: "Follow this guided tour to discover the main features of your dashboard.",
        },
      },
      {
        attachTo: { element: '#calendar' },
        content: {
          title: "📆 Today's schedule",
          description: "Have a look at your daily schedule. You can click on \"Calendar\" to access your detailed weekly schedule.",
        },
        options: {
          scrollToStep: {
            enabled: false,
          },
        }
      },
      {
        attachTo: { element: '#new-emails' },
        content: {
          title: "📨 New emails",
          description: "Once you've setup your Gmail account from the \"Settings\" page, your new (unread) emails will appear here.",
        },
      },
      {
        attachTo: { element: '#pending-prospects' },
        content: {
          title: "🙋‍♂️ Pending prospects",
          description: "Prospects pending your approval so that you can get in touch with them. Don't forget to add a payment method in your Settings to approve prospects!"
        },
      },
      {
        attachTo: { element: '#recent-patients' },
        content: {
          title: "👨 Recent patients",
          description: "Patients and prospects that have recently been added. The ones appearing in bold text have not been opened yet.",
        },
      },
      // Menu
      {
        attachTo: { element: '#menu-item-dashboard' },
        content: { title: "📊 Dashboard", description: "Essential information you need at a glance." },
        on: {
          beforeStep: () => {
            document.querySelector('body').removeAttribute('data-kt-aside-minimize');
          },
        },
      },
      {
        attachTo: { element: '#menu-item-emails' },
        content: { title: "📨 Emails", description: "Your mailbox (if a Gmail account has been linked in your Settings)." },
      },
      {
        attachTo: { element: '#menu-item-calendar' },
        content: { title: "🗓️ Calendar", description: "Your detailed calendar with all scheduled events (consultations, operations, etc.). Double-click on a slot to create a new event!" },
      },
      {
        attachTo: { element: '#menu-item-operations' },
        content: { title: "📆 Events", description: "Not a fan of the calendar view? Here is a list of your scheduled event.!" },
      },
      {
        attachTo: { element: '#menu-item-patients' },
        content: { title: "👨 Patients", description: "Find all your patients and prospects. Click on a patient to get detailed information and edit them." },
      },
      {
        attachTo: { element: '#menu-item-marketplace' },
        content: { title: "🏪 Marketplace", description: "Need to order pigments? You'll find them on our marketplace. Don't forget to add a payment method in your Settings to place an order." },
      },
      {
        attachTo: { element: '#menu-item-orders' },
        content: { title: "🛒 Orders", description: "Check your marketplace's orders history and status." },
      },
      {
        attachTo: { element: '#menu-item-invoices' },
        content: { title: "🧾 Invoices", description: "Accepted prospects and placed orders on the marketplace? All your invoices are here." },
      },
      {
        attachTo: { element: '#menu-item-stocks' },
        content: { title: "📦 Stocks", description: "Check and manage your pigments stocks. Stock is automatically adjusted each time you schedule an operation or place an order on the marketplace." },
      },
      {
        attachTo: { element: '#menu-item-email-templates' },
        content: { title: "📧 Email templates", description: "Tired of always writing the same thing? Create email templates that you will be able to re-use from your mailbox." },
      },
      {
        attachTo: { element: '#menu-item-tickets' },
        content: { title: "❓ Support", description: "An issue with the dashboard? You can create a ticket in our support system." },
      },
      {
        attachTo: { element: '#menu-item-settings' },
        content: { title: "⚙️ Settings", description: "Edit your clinic's settings: clinic name, contact and address, operation price, link to Gmail, payment methods, etc." },
        on: {
          afterStep: () => {
            document.querySelector('body').setAttribute('data-kt-aside-minimize', 'on');
          },
        }
      },
    ];

    return {
      wrapper,
      steps,
      startTour: start,
    }
  },
  data() {
    return {
      driver: null,
      isTermsModalOpen: false,
    };
  },
  mounted() {
    if (!JwtService.isMarketplaceOnly() && !localStorage.getItem("terms-seen-in-session")) {
      this.isTermsModalOpen = true;
    }

    if (this.$route.query.tour) {
      console.log("START TOUR");
      setTimeout(() => {
        this.startTour();
      }, 2000);
    }

    setCurrentPageTitle("Dashboard");
  },
  computed: {
    loggedUserName() {
      const user = JwtService.getUser();
      return `${user.firstname}`;
    },
  },
  methods: {
    refreshPatients() {
      this.$refs.patientsWidget.fetchItemsFromApi();
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-link.active {
  background-color: lighten(#dddee4, 4%) !important;
}

.menu-link:hover {
  background-color: lighten(#dddee4, 9%) !important;
}

.email-item:hover {
  background-color: rgba(221, 222, 228, 0.1);
}
</style>

<style lang="scss">
:root {
  --v-onboarding-overlay-z: 1000;
}

:root {
  --v-onboarding-step-z: 10000;
}

.v-onboarding-item {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background: rgb(245, 245, 245);
}

[data-v-onboarding-wrapper] [data-popper-arrow]:before {
  background: rgb(245, 245, 245);
}

button.v-onboarding-item__header-close {
  background: none;
  border: none;
}

button.v-onboarding-item__header-close:after {
  content: "✖";
}
</style>