<template>
  <!--begin::List Widget 1-->
  <div class="card">
    <!--begin::Body-->
    <div class="card-body p-0">
      <!--begin::Header-->
      <div class="px-9 pt-9 card-rounded">
        <!--begin::Heading-->
        <div class="d-flex flex-stack mb-6">
          <div class="d-flex flex-row align-items-center">
            <h3 class="m-0 fw-bolder fs-3 me-3">Recently updated prospects</h3>
          </div>
          <!-- <router-link :to="`/patients`" class="btn btn-light btn-sm"
            >See all patients</router-link
          > -->
        </div>
        <!--end::Heading-->
      </div>
      <!--end::Header-->

      <!--begin::Email list-->
      <!-- <div v-if="messagesLoading" class="pt-12 pb-8 text-center">
        <p>
          <span class="spinner-border spinner-border-sm align-middle me-2" />
          Loading...
        </p>
      </div>
      <div v-else class="px-9 pb-2"> -->
      <div class="px-9 pb-2">
        <div v-if="prospects.length">
          <div class="row pt-2">
            <div class="col-4">
              <p class="text-muted">Name</p>
            </div>
            <div class="col-3">
              <p class="text-muted">Country</p>
            </div>
            <div class="col-3">
              <p class="text-muted">Date updated</p>
            </div>
          </div>
          <div
            v-for="(prospect, idx) in prospects"
            :key="idx"
            class="py-6 row patient-row"
            :class="{ 'font-weight-bold': !prospect.seen }"
          >
            <p class="col-4 mb-0">
              {{ prospect.lastname.toUpperCase() }} {{ prospect.firstname }}
            </p>
            <p class="col-3 mb-0">
              {{ prospect.address.country }}
            </p>
            <p class="col-3 mb-0">
              {{
                new Date(prospect.updatedAt).toLocaleDateString([], {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
              }}
            </p>
            <router-link :to="`/patients/${prospect.id}`" class="col-2">
              See more
            </router-link>
          </div>
        </div>
        <div v-else>
          <p class="text-center py-12 text-muted">You have no recent prospect.</p>
        </div>
      </div>
      <!--end::Email list-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::List Widget 1-->
</template>

<script>
import ApiService from "@/core/services/ApiService";

export default {
  components: {},
  data() {
    return {
      isLoading: false,
      prospects: [],
    };
  },
  mounted() {
    this.fetchItemsFromApi();
  },
  methods: {
    /**
     * Fetch models from API and display them in the table
     */
    fetchItemsFromApi() {
      this.isLoading = true;

      const apiParams = {
        status: "HOT_PROSPECT",
        limit: 5,
        sortBy: "updatedAt:desc",
      };

      ApiService.query("/patients", { params: apiParams })
        .then(({ data }) => {
          this.prospects = data.results;
          console.log(this.prospects);
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-row {
  border-bottom: 0.6px solid #e4e6ef;

  &:last-of-type {
    border-bottom: none;
  }
}
</style>
