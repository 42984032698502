<template>
  <!--begin::List Widget 1-->
  <div class="card">
    <!--begin::Body-->
    <div class="card-body p-0">
      <!--begin::Header-->
      <div class="px-9 pt-9 card-rounded">
        <!--begin::Heading-->
        <div class="d-flex flex-stack">
          <div class="d-flex flex-row align-items-center">
            <h3 class="m-0 fw-bolder fs-3 me-3">New emails</h3>
            <span class="badge badge-square badge-light-info">{{ messages.length }}</span>
          </div>
          <router-link :to="`/emails`" class="btn btn-light btn-sm">See all emails</router-link>
        </div>
        <!--end::Heading-->
      </div>
      <!--end::Header-->

      <!--begin::Email list-->
      <div v-if="messagesLoading" class="pt-12 pb-8 text-center">
        <p>
          <span class="spinner-border spinner-border-sm align-middle me-2" />
          Loading...
        </p>
      </div>
      <div v-else>
        <div v-if="!loggedUserHasGmailLinked" class="py-12 text-center">
          <p class="text-muted">No Gmail account linked to your Neoris dashboard.</p>
        </div>
        <div v-else-if="!messages.length" class="py-12 text-center">
          <p class="text-muted">You have no new email.</p>
        </div>
        <div class="card-body pt-6 pb-0 px-0">
          <!--begin::Email list item-->
          <router-link
            v-for="message in messages"
            :key="message.id"
            :to="`/emails/${message.threadId}`"
            class="email-item d-flex flex-column text-dark"
          >
            <div class="d-flex flex-row align-items-center row px-9 py-6">
              <!--begin::Author-->
              <div class="mail-author col-3 d-flex flex-row align-items-center">
                <!--begin::Avatar-->
                <div class="symbol symbol-35px me-3">
                  <div class="symbol-label bg-info">
                    <span class="text-white">
                      {{ toInitials(message.from) }}
                    </span>
                  </div>
                </div>
                <!--end::Avatar-->
                <p class="mb-0 fw-bold">
                  {{
                    message.from.substring(0, message.from.indexOf("&lt;")) ||
                    message.from
                  }}
                </p>
              </div>
              <!--end::Author-->
              <!--begin::Mail content-->
              <div class="mail-content col-6">
                <p class="mb-0 fw-bolder">{{ message.subject }}</p>
              </div>
              <!--end::Mail content-->
              <!--begin::Info-->
              <div
                class="
                  mail-info
                  col-3
                  d-flex
                  flex-row
                  justify-content-end
                  text-end
                  align-items-center
                "
              >
                <p class="mb-0 fw-bolder">
                  {{ timeAgo.format(new Date(message.date)) }}
                </p>
              </div>
              <!--end::Info-->
            </div>
            <div class="separator"></div>
          </router-link>
          <!--end::Email list item-->
        </div>
      </div>
      <!--end::Email list-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::List Widget 1-->
</template>

<script>
import ApiService from "@/core/services/ApiService";
import TimeAgo from "javascript-time-ago";
import JwtService from "@/core/services/JwtService";
import en from "javascript-time-ago/locale/en.json";

try {
  TimeAgo.addDefaultLocale(en);
} catch (e) {
  console.log("timeago", e);
}

export default {
  components: {},
  data() {
    return {
      messagesLoading: true,
      messages: [],
      timeAgo: null,
    };
  },
  computed: {
    loggedUserHasGmailLinked() {
      const user = JwtService.getUser();
      if (!user) return false;
      return !!user.clinic.gmailApiToken && user.clinic.gmailApiToken.access_token;
    },
  },
  created() {
    this.timeAgo = new TimeAgo("en-US");
    if (this.loggedUserHasGmailLinked) {
      this.fetchEmails();
    } else {
      this.messagesLoading = false;
    }
  },
  methods: {
    onSearchEmail() {
      this.fetchEmails();
    },
    toInitials(str) {
      try {
        return str
          .match(/\b(\w)/g)
          .join("")
          .slice(0, 2)
          .toUpperCase();
      } catch (err) {
        return str.substring(0, 2).toUpperCase();
      }
    },
    fetchEmails() {
      this.messagesLoading = true;

      const params = {
        maxResults: 5,
        // q: `subject:"NEORIS"`,
      };

      ApiService.query("gmail/threads", { params })
        .then(({ data: { parsedMessages } }) => {
          this.messages.push(
            ...parsedMessages.filter((message) =>
              message.labels.includes("UNREAD")
            )
          );
          this.messagesLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.messagesLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-link.active {
  background-color: lighten(#dddee4, 4%) !important;
}
.menu-link:hover {
  background-color: lighten(#dddee4, 9%) !important;
}
.email-item:hover {
  background-color: rgba(221, 222, 228, 0.1);
}
</style>
