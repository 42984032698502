<template>
    <vue-final-modal v-slot="{ close }" v-bind="$attrs" classes="modal-container" content-class="modal-content"
        @beforeOpen="(event) => processParams(event.ref.params._rawValue)">
        <!-- Begin::Header -->
        <div class="modal__header">
            <span class="modal__title">
                Notice
            </span>
        </div>
        <!-- End::Header -->
        <!-- Begin::Body -->
        <div class="modal__content">
            <!-- Begin::Details section -->
            <div class="row">
                <div class="offset-2 col-8">
                    <h2 class="text-center text-uppercase mt-4 mb-8">Neoris Dashboard, Important reminder</h2>

                    <p class="lead">
                        <strong>Information and appointment inquiries from prospects are available during two days.</strong>
                        After this time, the prospect will no longer appear on your Dashboard.
                    </p>

                    <!-- <details>
                        <summary class="mb-8">More details</summary>
                        <p>
                            TODO: Add more details here.
                        </p>
                    </details> -->

                    <div class="d-block">
                        <button class="btn btn-lg btn-primary me-2" @click="() => { seen(); close(); }">Ok</button>
                    </div>
                </div>
            </div>
        </div>
        <button class="modal__close btn btn-icon btn-link" @click="() => { seen(); close(); }">
            <i class="bi bi-x-lg"></i>
        </button>
    </vue-final-modal>
</template>
  
<script>
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
const clinicId = JwtService.getUser().clinic.id;

import { useToast } from "vue-toastification";

export default {
    emits: ["confirm"],
    setup() {
        return {
            toast: useToast(),
        };
    },
    data() {
        return {
            acceptanceRateLastMonth: null,
        };
    },
    methods: {
        seen() {
            localStorage.setItem("terms-seen-in-session", true);
        },
        processParams(params) {
            console.log(params)

            // Last day of prev. month
            const to = new Date(); // current date
            to.setDate(1); // going to 1st of the month
            to.setHours(-1); // going to last hour before this date even started.
            
            const from = new Date(to);
            from.setDate(1); // going to 1st of the month

            const fromStr = `${from.toISOString().substring(0, 10)}T00:00:00`;
            const toStr = `${to.toISOString().substring(0, 10)}T23:59:59`;

            ApiService.query(`clinics/${clinicId}/prospects-acceptance-rate?from=${fromStr}&to=${toStr}`).then(({ data }) => {
                this.acceptanceRateLastMonth = data.acceptanceRate;
            });
        },
    },
};
</script>
  