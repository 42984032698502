<template>
  <!--begin::List Widget 1-->
  <div class="card">
    <!--begin::Body-->
    <div class="card-body p-0">
      <!--begin::Header-->
      <div class="px-9 pt-9 card-rounded">
        <!--begin::Heading-->
        <div class="d-flex flex-stack">
          <div class="d-flex flex-row align-items-center">
            <h3 class="m-0 fw-bolder fs-3 me-3">Today's schedule</h3>
          </div>
          <router-link :to="`/calendar`" class="btn btn-light btn-sm"
            >See calendar</router-link
          >
        </div>
        <!--end::Heading-->
      </div>
      <!--end::Header-->

      <!--begin::Email list-->
      <!-- <div v-if="messagesLoading" class="pt-12 pb-8 text-center">
        <p>
          <span class="spinner-border spinner-border-sm align-middle me-2" />
          Loading...
        </p>
      </div>
      <div v-else> -->
      <div>
        <vue-cal
          v-if="eventsItems"
          ref="calendar"
          class="mt-6"
          :time-from="8 * 60"
          :time-to="18 * 60"
          :disable-views="['years', 'year', 'month', 'week']"
          :timeCellHeight="80"
          active-view="day"
          :split-days="showDoctorsSplits"
          :events="eventsItems"
          :on-event-click="onEventClick"
          :selected-date="selectedDate"
          cell-contextmenu
          :drag-to-create-threshold="5"
          :dblclick-to-navigate="false"
          :hide-title-bar="true"
          :hide-view-selector="true"
        />
        <!--end::Email list item-->
      </div>
      <!--end::Email list-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::List Widget 1-->
</template>

<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import EventType from "@/core/constants/EventType";
import ApiService from "@/core/services/ApiService";

export default {
  components: {
    VueCal,
  },
  data() {
    return {
      isLoading: false,
      selectedDate: null,
      eventsItems: [],
      showDoctorsSplits: [],
      doctorsSplits: [],
      totalEvents: 0,
      isNewOperationModalOpen: false,
    };
  },
  mounted() {
    this.fetchItemsFromApi();
  },
  methods: {
    createEvent(e) {
      if (e instanceof Date) {
        e.setHours(e.getHours(), 0, 0);
        this.openNewOperation({
          start: e,
          type: "CONSULTATION",
        });
      } else {
        e.date.setHours(e.date.getHours(), 0, 0);
        const doctor = this.doctorsSplits.find((split) => split.id == e.split);
        this.openNewOperation({
          start: e.date,
          doctor: { id: doctor.doctorId },
          type: "CONSULTATION",
        });
      }
    },
    onEventClick(event, e) {
      this.$router.push({ name: "operation", params: { id: event.id } });

      // Prevent navigating to narrower view (default vue-cal behavior).
      e.stopPropagation();
    },
    /**
     * Fetch models from API and display them in the table
     */
    fetchItemsFromApi() {
      this.isLoading = true;

      const getApiParams = {
        populate: "doctor patient products",
        limit: 10000000,
      };

      ApiService.query("/operations", { params: getApiParams })
        .then((res) => {
          console.log(res);
          this.eventsItems = res.data.results.map((event) => {

            // Event title
            try {
              event.title = `<strong>${event.patient.firstname} ${event.patient.lastname}</strong><br>${EventType[event.type].label}`;
            } catch (err) {
              event.title = `<strong>${event.type}</strong>`;
            }

            // Event description
            try {
              event.content = `Dr. ${event.doctor.firstname} ${event.doctor.lastname}`;
            } catch (err) {
              event.content = '';
            }

            // Event timeframe
            event.start = new Date(event.start);
            const minutes = event.duration.hours * 60 + event.duration.minutes;
            event.end = new Date(event.start.getTime() + minutes * 60000);

            // Split days per doctor
            try {
              const split = this.doctorsSplits.find(
                (e) => e.label === `Dr. ${event.doctor.lastname}`
              );
              if (split) {
                event.split = split.id;
              } else {
                event.split = this.doctorsSplits.length + 1;
                this.doctorsSplits.push({
                  id: event.split,
                  label: `Dr. ${event.doctor.lastname}`,
                  doctorId: event.doctor.id,
                });
              }
            } catch (err) {
              console.warn(err);
            }

            event.class = EventType[event.type].colorClass;
            if (event.downPayment) {
              event.class += " down-paid";
            }
            return event;
          });

          console.log(this.eventsItems);

          this.totalEvents = res.data.totalResults;
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.vuecal__cell-events-count {
  width: 18px;
  height: 2px;
  color: transparent;
}

.vuecal__event {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.35);
  border-left: 5px solid rgba(241, 65, 108, 0.8);

  &.down-paid {
    border-left: 5px solid rgba(26, 227, 19, 0.8);
  }
  &.info {
    background-color: rgba(34, 55, 240, 0.35);
  }
  &.danger {
    background-color: rgba(241, 65, 108, 0.35);
  }
  &.success {
    background-color: rgba(80, 205, 136, 0.35);
  }
}

/* Green-theme. */
.vuecal__menu,
.vuecal__cell-events-count {
  background-color: #111;
  color: #eff2f5;
}
.vuecal__title-bar {
  background-color: #eaebf5;
}
.vuecal__cell--today,
.vuecal__cell--current {
  background-color: rgba(225, 225, 225, 0.4);
}
.vuecal:not(.vuecal--day-view) .vuecal__cell--selected {
  background-color: rgba(225, 225, 225, 0.4);
}
.vuecal__cell--selected:before {
  border-color: rgba(17, 17, 17, 0.5);
}
/* Cells and buttons get highlighted when an event is dragged over it. */
.vuecal__cell--highlighted:not(.vuecal__cell--has-splits),
.vuecal__cell-split--highlighted {
  background-color: rgba(255, 255, 225, 0.5);
}
.vuecal__arrow.vuecal__arrow--highlighted,
.vuecal__view-btn.vuecal__view-btn--highlighted {
  background-color: rgba(172, 172, 172, 0.25);
}
</style>
